<template>
  <div id="Right" class="layout">
    <div
      class="noti"
      :style="{ height: isOpen.noti && isOpen.msg ? '50%' : isOpen.noti && !isOpen.msg ? '100%' : '0', display: isOpen.noti ? 'block' : 'none' }"
    >
      <h3>알림</h3>
      <ul>
        <li>noti1</li>
        <li>noti2</li>
        <li>noti3</li>
      </ul>
    </div>
    <div
      class="msg"
      :style="{ height: isOpen.noti && isOpen.msg ? '50%' : !isOpen.noti && isOpen.msg ? '100%' : '0', display: isOpen.msg ? 'block' : 'none' }"
    >
      <h3>메시지<button @click="newRoom"><f-icon icon="plus"/>새로운 대화</button></h3>
      <ul>
        <li v-for="room in roomList" @dblclick="openChat(room.room_id)">{{room.room_name}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/utils/EventBus.js";
import APIUtils from "@/utils/Network/APIUtils";
import {mapGetters} from "vuex";
export default {
  data() {
    return {
      isOpen: {
        noti: false,
        msg: false,
      },
      roomList: []
    };
  },
  computed: {
    ...mapGetters({ info: "getSaveInfo"}),
  },
  mounted() {
    let objThis = this;
    EventBus.$on("bus:openNoti", function(isOpen) {
      objThis.isOpen.noti = isOpen;
      if (!objThis.isOpen.noti && !objThis.isOpen.msg) {
        document.documentElement.style.setProperty("--size-right", "0px");
      } else {
        document.documentElement.style.setProperty("--size-right", "240px");
      }
    });
    EventBus.$on("bus:openMsg", function(isOpen) {
      objThis.isOpen.msg = isOpen;
      if (!objThis.isOpen.noti && !objThis.isOpen.msg) {
        document.documentElement.style.setProperty("--size-right", "0px");
      } else {
        objThis.roomList = objThis.info.chat
        document.documentElement.style.setProperty("--size-right", "240px");
      }
    });
    document.documentElement.style.setProperty("--size-right", "0px");
  },
  methods: {
    openChat(room_id){
      EventBus.$emit("bus:openChat", room_id)
    },
    newRoom(){

    }
  },
};
</script>

<style scoped></style>
